// matching locales from locales server constants as of 6/6/2017
require('moment/locale/af');
require('moment/locale/ar');
require('moment/locale/be');
require('moment/locale/bg');
require('moment/locale/bn');
require('moment/locale/ca');
require('moment/locale/cs');
require('moment/locale/cy');
require('moment/locale/da');
require('moment/locale/de');
require('moment/locale/el');
require('moment/locale/en-gb');
require('moment/locale/es');
require('moment/locale/eu');
require('moment/locale/fa');
require('moment/locale/fi');
require('moment/locale/tl-ph');
require('moment/locale/fr');
require('moment/locale/gl');
require('moment/locale/he');
require('moment/locale/hi');
require('moment/locale/hr');
require('moment/locale/hu');
require('moment/locale/id');
require('moment/locale/is');
require('moment/locale/it');
require('moment/locale/ja');
require('moment/locale/ko');
require('moment/locale/lv');
require('moment/locale/ms');
require('moment/locale/nb');
require('moment/locale/nl');
require('moment/locale/pl');
require('moment/locale/pt');
require('moment/locale/ro');
require('moment/locale/ru');
require('moment/locale/sk');
require('moment/locale/sq');
require('moment/locale/sr');
require('moment/locale/sv');
require('moment/locale/ta');
require('moment/locale/th');
require('moment/locale/tr');
require('moment/locale/uk');
require('moment/locale/ur');
require('moment/locale/vi');
require('moment/locale/zh-cn');
